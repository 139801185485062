// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import MathQuill from 'js/mathquill.js'
import 'scss/mathquill.scss'
import 'bootstrap'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import ClipboardJS from "js/clipboard.js"
import html2canvas from "html2canvas"
import { saveAs } from 'file-saver'
import Canvas2Image from "js/canvas2image.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// CSS
import '../scss/mathquill.scss'
// import 'scss/site'
// // JS
// import('js/site')
// Images
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("bootstrap")
// import "../stylesheets/application";
document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
})

import "@fortawesome/fontawesome-free/js/all"

$(document).on("turbolinks:load", function() {
  gtag('config', 'G-VBV6R865JH', {'page_location': event.data.url});
})
$(document).ready(function () { 
// $(document).on("turbolinks:load" ,function(){
  console.log("Loading mathquill");
  var latexSource = $('#latex-source');

  var MQ = MathQuill.getInterface(MathQuill.getInterface.MAX);
  var mathField = MQ.MathField(document.getElementById('editable-math'), {
    spaceBehavesLikeTab: true, // configurable
    handlers: {
      edit: function() { // useful event handlers
        latexSource.val( mathField.latex() ); // simple API
      }
    }
  });

  $(".add-cmd").on("click", function(){
    $.each($(this).data('cmd').split("---"), function(index, value){
      mathField.cmd(value);
    })
    mathField.focus();
    isDisabledButton();
  })
  $(".copy-source").on("click", function(){
    $('.toast-copied-source').removeClass('d-none');
    setTimeout(function() { 
      $('.toast-copied-source').addClass('d-none')
    }, 3000 );
  });
  $("#screenshotSave").on("click", function(){
    $('.toast-copied-screenshot').removeClass('d-none')
    setTimeout(function() { 
      $('.toast-copied-screenshot').addClass('d-none')
    }, 3000 );
  });
  var clipboard = new ClipboardJS('.btn');

  $("#screenshotSave").click(function() {
    $(".mathBox button").hide();
    $(".mathBox .no-scr").hide();
    $(".mq-editable-field").css("border-color", "white")
    html2canvas(document.querySelector(".mq-root-block")).then(canvas => {
      Canvas2Image.saveAsImage(canvas);
      $(".mathBox button").show();
      $(".mathBox .no-scr").show();
      $(".mq-editable-field").css("border-color", "black")
    });
  });

  var isDisabledButton = function() {
    if($("#latex-source").val() == "") {
      $(".copied-screenshot button, .copy-source").attr("disabled", true);
    } else {
      $(".copied-screenshot button, .copy-source").removeAttr("disabled");
    }
  }

  isDisabledButton();

  $("#editable-math").on("keyup", "textarea", function(){
    isDisabledButton();
  });
});
window.onload=function(){
  document.getElementById('my_button').onclick = function() {
    console.log("Loading XXX");
    var ta = document.getElementsByClassName("mathquill-math-field")[0];
      ta.focus();
      console.log("Loading XXX2");
  };
}
